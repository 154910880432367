import { fonts } from 'styles/fonts';
import posthog from 'posthog-js';

import { isDev } from 'utils';

export { default as wrapRootElement } from './wrap-root-element';

let posthogInitialized = false;

const initPosthog = () => {
  if (posthogInitialized) return;
  posthogInitialized = true;

  posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
    loaded: (posthog) => {
      window.posthog = posthog;

      if (isDev()) {
        posthog.opt_out_capturing();
      }
    },
  });

  window.removeEventListener('mousemove', initPosthog);
  window.removeEventListener('scroll', initPosthog);
  window.removeEventListener('keydown', initPosthog);
};

window.addEventListener('mousemove', initPosthog, { once: true });
window.addEventListener('scroll', initPosthog, { once: true });
window.addEventListener('keydown', initPosthog, { once: true });

export function onInitialClientRender() {
  // Load fonts.
  if (typeof document !== 'undefined' && 'fonts' in document) {
    fonts.forEach(async ({ family, src, descriptors }) => {
      const source = `url(${src.url}) format('${src.format}')`;
      const fontFace = new FontFace(family, source, descriptors);
      const font = await fontFace.load();
      document.fonts.add(font);
    });
  }

  // A 'scrollend' event polyfill.
  // Used by the Carousel component.
  // @link https://github.com/argyleink/scrollyfills
  // eslint-disable-next-line no-unused-vars
  import('scrollyfills');
}

export const onClientEntry = () => {
  // G2 tracking script
  (function (c, p, d, u, id, i) {
    id = ''; // Optional Custom ID for user in your system
    u =
      'https://tracking-api.g2.com/attribution_tracking/conversions/' +
      c +
      '.js?p=' +
      encodeURI(p) +
      '&e=' +
      id;
    i = document.createElement('script');
    i.type = 'application/javascript';
    i.async = true;
    i.src = u;
    d.getElementsByTagName('head')[0].appendChild(i);
  })('5904', document.location.href, document);
};
